import React from "react"

import { CustomHeader } from "react-calendar-timeline"
import "react-calendar-timeline/lib/Timeline.css"
import styled from "styled-components"

import { Stack, Text } from "@kiwicom/orbit-components"

import { snakeCaseToTitleCase } from "utils/string-utils"

const TimeWrapper = styled.span`
  display: block;
  position: -webkit-sticky;
  position: sticky;
  left: 10px;
  right: 10px;
  padding: 0 10px 0 10px;
`

export const CustomDateHeader = ({
  headerUnit,
  className,
  primary = false,
}) => {
  return (
    <CustomHeader unit={headerUnit}>
      {({
        headerContext: { unit, intervals },
        getRootProps,
        getIntervalProps,
      }) => {
        return (
          <div {...getRootProps()}>
            {intervals.map((interval) => {
              const intervalText = formatLabel(
                interval.startTime,
                unit,
                interval.labelWidth
              )

              return (
                <div
                  className={className}
                  key={intervalText}
                  {...getIntervalProps({
                    interval,
                  })}
                >
                  <TimeWrapper>
                    {" "}
                    <Text
                      type="white"
                      weight={primary ? "medium" : "normal"}
                      size="small"
                    >
                      {" "}
                      {intervalText}
                    </Text>
                  </TimeWrapper>
                </div>
              )
            })}
          </div>
        )
      }}
    </CustomHeader>
  )
}
const headerFormats = {
  month: {
    long: "MMM YYYY",
    mediumLong: "MMM YYYY",
    medium: "MMMM",
    short: "MM/YY",
  },
  day: {
    long: "ddd D MMM YYYY",
    mediumLong: "ddd D MMM",
    medium: "dd D",
    short: "D",
  },
  hour: {
    long: "HH:00",
    mediumLong: "HH:00",
    medium: "HH:00",
    short: "HH",
  },
  minute: {
    long: "HH:mm",
    mediumLong: "HH:mm",
    medium: "HH:mm",
    short: "mm",
  },
  second: {
    long: "mm:ss",
    mediumLong: "mm:ss",
    medium: "mm:ss",
    short: "ss",
  },
}
function formatLabel(timeStart, unit, labelWidth) {
  let format
  if (labelWidth >= 1000) {
    format = headerFormats[unit]["long"]
  } else if (labelWidth >= 120) {
    format = headerFormats[unit]["mediumLong"]
  } else if (labelWidth >= 50) {
    format = headerFormats[unit]["medium"]
  } else {
    format = headerFormats[unit]["short"]
  }
  return timeStart.format(format)
}

export function itemRenderer({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}) {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const props = getItemProps(item.itemProps)
  const selectedClassName = itemContext.selected ? "--selected" : ""
  props.className = `rct-item-ember rct-item-ember--${item.type}${selectedClassName} `
  delete props.style.background
  delete props.style.border
  const itemWidth = parseFloat(props.style.width.split("px")[0])
  const isTrip = item.type == "trip"

  return (
    <div {...props}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

      <div
        className="rct-item-content"
        style={{ maxHeight: `${itemContext.dimensions.height}` }}
      >
        <Stack direction="row" align="baseline" spacing="XXSmall">
          {!isTrip && 70 <= itemWidth && (
            <Text align="center" weight="medium" size="small" type="white">
              {snakeCaseToTitleCase(itemContext.title)}
            </Text>
          )}

          {isTrip && 35 <= itemWidth && (
            <Text align="center" weight="bold" size="small" type="white">
              {snakeCaseToTitleCase(itemContext.title)}
            </Text>
          )}

          {isTrip && 120 <= itemWidth && (
            <Text type="white" size="small">
              To {item.destination}
            </Text>
          )}
        </Stack>
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
    </div>
  )
}

type CalendarWrapperProps = {
  isDesktop: boolean
}

export const CalendarWrapper = styled.div<CalendarWrapperProps>`
  flex: 1 1 auto;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 0px;

  .react-calendar-timeline .rct-header-root {
    position: sticky;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
    background: ${(props) => props.theme.orbit.paletteInkNormal};
    border-bottom: 1px solid ${(props) => props.theme.orbit.paletteCloudDark};
  }
  .rct-item-content {
    color: ${(props) => props.theme.orbit.paletteInkWhite};
    line-height: 18px;
    white-space: normal;
    padding: 2px;
  }
  .rct-item-ember {
    background: ${(props) => props.theme.orbit.paletteGreenDark};
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
    border-radius: 6px;
    height: 100%;
  }
  .rct-item-ember--selected {
    background: ${(props) => props.theme.orbit.paletteGreenNormalActive};
  }
  .rct-items {
    .rct-item-ember--safety_check {
      background: ${(props) => props.theme.orbit.paletteGreenNormal};
    }
    .rct-item-ember--safety_check--selected {
      background: ${(props) => props.theme.orbit.paletteGreenNormalActive};
    }

    .rct-item-ember--put_vehicle_on_charge {
      background: ${(props) => props.theme.orbit.paletteBlueNormal};
    }
    .rct-item-ember--put_vehicle_on_charge--selected {
      background: ${(props) => props.theme.orbit.paletteBlueNormalActive};
    }

    .rct-item-ember--cleaning {
      background: ${(props) => props.theme.orbit.paletteOrangeNormal};
    }
    .rct-item-ember--cleaning--selected {
      background: ${(props) => props.theme.orbit.paletteOrangeNormalActive};
    }

    .rct-item-ember--wash {
      background: ${(props) => props.theme.orbit.paletteOrangeNormal};
    }
    .rct-item-ember--wash--selected {
      background: ${(props) => props.theme.orbit.paletteOrangeNormalActive};
    }

    .rct-item-ember--timeOff_annual_leave {
      background: ${(props) => props.theme.orbit.paletteRedNormal};
    }

    .rct-item-ember--timeOff_other {
      background: ${(props) => props.theme.orbit.paletteRedDark};
    }

    .rct-item-ember--very-early-morning-shift {
      background: ${(props) => props.theme.ember.palette.morning.veryEarly};
    }

    .rct-item-ember--early-morning-shift {
      background: ${(props) => props.theme.ember.palette.morning.early};
    }

    .rct-item-ember--morning-shift {
      background: ${(props) => props.theme.ember.palette.morning.normal};
    }

    .rct-item-ember--early-afternoon-shift {
      background: ${(props) => props.theme.ember.palette.afternoon.early};
    }

    .rct-item-ember--afternoon-shift {
      background: ${(props) => props.theme.ember.palette.afternoon.normal};
    }

    .rct-item-ember--evening-shift {
      background: ${(props) => props.theme.ember.palette.evening.normal};
    }

    .rct-item-ember--late-shift {
      background: ${(props) => props.theme.ember.palette.evening.late};
    }
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: unset;
  }
  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid ${(props) => props.theme.orbit.paletteCloudNormal};
  }

  .rct-vertical-lines {
    position: relative;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    border-bottom: 1px solid ${(props) => props.theme.orbit.paletteInkLight};
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid ${(props) => props.theme.orbit.paletteInkLight};
    background: transparent;
  }
  .react-calendar-timeline .rct-sidebar {
    border: unset;
    .rct-sidebar-row.rct-sidebar-row-odd {
      background: ${(props) => props.theme.orbit.paletteInkNormal};
    }
    .rct-sidebar-row.rct-sidebar-row-even {
      background: ${(props) => props.theme.orbit.paletteInkNormal};
    }
  }

  .react-calendar-timeline .rct-calendar-header {
    border: unset;
  }

  .react-calendar-timeline .rct-dateHeader {
    cursor: auto;
    background: ${(props) => props.theme.orbit.paletteInkNormal};
    border: unset;
    border-left: 1px solid ${(props) => props.theme.orbit.paletteCloudNormal};
  }

  .react-calendar-timeline .rct-scroll {
    overflow-x: ${(props) => (props.isDesktop ? "scroll" : "hidden")};
    touch-action: ${(props) => (props.isDesktop ? "none" : "pan-y")};
  }
`
