import React from "react"

import { Stack } from "@kiwicom/orbit-components/lib"

import { DateRange } from "components/generic/date-time/ember-date-range-picker"
import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"

import { Activity } from "types/activity"

import { ActivityForm } from "./activity-forms/form-vehicle-activity"

interface ModalProps {
  dateRange: DateRange
  activityToEdit?: Activity
  refreshActivities: () => void
  handleClose: () => void
  initialVehicleId?: string
}

const ActivityModal = ({
  dateRange,
  activityToEdit,
  refreshActivities,
  handleClose,
  initialVehicleId,
}: ModalProps) => {
  function refreshAfterChange() {
    refreshActivities()
    handleClose()
  }

  return (
    <EmberModal size="small" onClose={handleClose}>
      <EmberModalHeader title="Activity" />
      <EmberModalSection>
        <Stack spacing="large">
          <ActivityForm
            refreshAfterChange={refreshAfterChange}
            initialDateRange={dateRange}
            activityToEdit={activityToEdit}
            initialVehicleId={initialVehicleId}
          />
        </Stack>
      </EmberModalSection>
    </EmberModal>
  )
}

export { ActivityModal }
