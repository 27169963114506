// @ts-strict-ignore
import React, { useState } from "react"

import {
  addHours,
  addSeconds,
  endOfHour,
  startOfDay,
  subMinutes,
} from "date-fns"

import { Button, Stack, useMediaQuery } from "@kiwicom/orbit-components"
import { MinusCircle, Plus, PlusCircle } from "@kiwicom/orbit-components/icons"

import { ActivityModal } from "components/admin-shifts/activity-modal"
import { EmberDatePicker } from "components/generic/date-time/ember-date-picker"
import { Filter } from "components/generic/filter-menu"
import { EmberCheckboxOptionProps } from "components/generic/formik-inputs"
import NavBarLink from "components/generic/navigation/nav-bar-link"
import { TertiaryNavBarWithLeftMenu } from "components/nav-bar-tertiary"

import EmberTimeline, {
  ActiveFilterValue,
  ScheduleType,
  VehicleTypeFilterValue,
  zoomLevelArray,
} from "./timeline"

interface VehiclesScheduleFilterProps {
  vehicleFilterOptions: EmberCheckboxOptionProps[]
  vehicleFilter: string[]
  setVehicleFilter: (type: string[]) => void
  vehicleTypeFilter: VehicleTypeFilterValue
  setVehicleTypeFilter: (value: VehicleTypeFilterValue) => void
}

const VehiclesScheduleFilter = ({
  vehicleFilterOptions,
  vehicleFilter,
  setVehicleFilter,
  vehicleTypeFilter,
  setVehicleTypeFilter,
}: VehiclesScheduleFilterProps) => {
  return (
    <Filter
      filters={[
        {
          type: "radio",
          name: "activeVehicles",
          title: "Type",
          state: vehicleTypeFilter,
          options: [
            {
              value: VehicleTypeFilterValue.primary,
              label: "Primary",
            },
            {
              value: VehicleTypeFilterValue.all,
              label: "All",
            },
          ],
          setState: setVehicleTypeFilter,
        },
        {
          type: "checkbox",
          name: "vehicles",
          title: "Vehicles",
          state: vehicleFilter,
          options: vehicleFilterOptions,
          setState: setVehicleFilter,
        },
      ]}
      smallIcon
    />
  )
}

interface PeopleScheduleFilterProps {
  peopleFilterOptions: EmberCheckboxOptionProps[]
  peopleTypeFilter: ActiveFilterValue
  setPeopleTypeFilter: (value: ActiveFilterValue) => void
  peopleFilter: string[]
  setPeopleFilter: (type: string[]) => void
}

const PeopleScheduleFilter = ({
  peopleFilterOptions,
  peopleTypeFilter,
  setPeopleTypeFilter,
  peopleFilter,
  setPeopleFilter,
}: PeopleScheduleFilterProps) => {
  return (
    <Filter
      filters={[
        {
          type: "radio",
          name: "activePeople",
          title: "Type",
          state: peopleTypeFilter,
          options: [
            {
              value: ActiveFilterValue.all,
              label: "All Drivers",
            },
            {
              value: ActiveFilterValue.active,
              label: "Active",
            },
          ],
          setState: setPeopleTypeFilter,
        },
        {
          type: "checkbox",
          name: "person",
          title: "Driver",
          state: peopleFilter,
          options: peopleFilterOptions,
          setState: setPeopleFilter,
        },
      ]}
      smallIcon
    />
  )
}

const SchedulePage = () => {
  const [scheduleType, setScheduleType] = useState<ScheduleType>(
    ScheduleType.people
  )
  const [showActivityModal, setShowActivityModal] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(1)
  const [zoomLevel, setZoomLevel] = useState<number>(0)
  const media = useMediaQuery()
  const [date, setDate] = useState(subMinutes(new Date(), 30))
  const [leftBorderDate, setLeftBorderDate] = useState(
    addSeconds(endOfHour(new Date()), 1)
  )
  const updateDate = (_date: Date) => {
    setDate(startOfDay(_date))
  }
  // Filter Options
  const [vehicleFilterOptions, setVehicleFilterOptions] = useState([])
  const [peopleFilterOptions, setPeopleFilterOptions] = useState([])
  // Filters
  const [vehicleScheduleTypeFilter, setVehiclesScheduleTypeFilter] =
    useState<VehicleTypeFilterValue>(VehicleTypeFilterValue.primary)
  const [vehiclesScheduleFilterVehicle, setVehiclesScheduleFilterVehicle] =
    useState([])
  const [peopleScheduleFilterActive, setPeopleScheduleFilterActive] =
    useState<ActiveFilterValue>(ActiveFilterValue.active)
  const [peopleScheduleFilterPeople, setPeopleScheduleFilterPeople] = useState(
    []
  )

  const refreshFunc = () => setRefreshCounter(refreshCounter + 1)
  const updateLeftBorderDate = (ts: number) => {
    setLeftBorderDate(addSeconds(endOfHour(new Date(ts)), 1))
  }

  return (
    <>
      {showActivityModal && (
        <ActivityModal
          dateRange={{
            start: leftBorderDate,
            end: addHours(leftBorderDate, 1),
          }}
          refreshActivities={refreshFunc}
          handleClose={() => {
            setShowActivityModal(false)
          }}
        />
      )}
      <TertiaryNavBarWithLeftMenu
        menuLabel={
          media.isMediumMobile
            ? scheduleType == "people"
              ? "People"
              : "Vehicles"
            : null
        }
        rightContent={
          <Stack
            direction="row"
            justify="start"
            align="center"
            spacing="XSmall"
            largeMobile={{ spacing: "medium" }}
          >
            <EmberDatePicker date={date} setDate={updateDate} iconOnly />
            <>
              {scheduleType == ScheduleType.vehicles && (
                <>
                  <VehiclesScheduleFilter
                    vehicleFilterOptions={vehicleFilterOptions}
                    vehicleFilter={vehiclesScheduleFilterVehicle}
                    setVehicleFilter={setVehiclesScheduleFilterVehicle}
                    vehicleTypeFilter={vehicleScheduleTypeFilter}
                    setVehicleTypeFilter={setVehiclesScheduleTypeFilter}
                  />
                  <Button
                    type="secondary"
                    size="small"
                    onClick={() => {
                      setShowActivityModal(true)
                    }}
                    iconLeft={<Plus />}
                  >
                    {media.isLargeMobile === true && "Create Activity"}
                  </Button>
                </>
              )}
              {scheduleType == ScheduleType.people && (
                <PeopleScheduleFilter
                  peopleFilterOptions={peopleFilterOptions}
                  peopleTypeFilter={peopleScheduleFilterActive}
                  setPeopleTypeFilter={setPeopleScheduleFilterActive}
                  peopleFilter={peopleScheduleFilterPeople}
                  setPeopleFilter={setPeopleScheduleFilterPeople}
                />
              )}
              <Stack direction="row" spacing="XXXSmall" shrink>
                <Button
                  type="secondary"
                  size="small"
                  disabled={zoomLevel == zoomLevelArray.length - 1}
                  onClick={() => {
                    setZoomLevel(zoomLevel + 1)
                  }}
                  iconLeft={<MinusCircle />}
                />
                <Button
                  type="secondary"
                  size="small"
                  disabled={zoomLevel == 0}
                  onClick={() => {
                    setZoomLevel(zoomLevel - 1)
                  }}
                  iconLeft={<PlusCircle />}
                />
              </Stack>
            </>
          </Stack>
        }
      >
        <NavBarLink to={() => setScheduleType(ScheduleType.people)}>
          People
        </NavBarLink>
        <NavBarLink to={() => setScheduleType(ScheduleType.vehicles)}>
          Vehicles
        </NavBarLink>
      </TertiaryNavBarWithLeftMenu>
      <EmberTimeline
        scheduleType={scheduleType}
        filterSchedule={{
          vehicles: {
            ids: vehiclesScheduleFilterVehicle,
            type: vehicleScheduleTypeFilter,
          },
          people: {
            ids: peopleScheduleFilterPeople,
            type: peopleScheduleFilterActive,
          },
        }}
        setVehicleFilterOptions={setVehicleFilterOptions}
        setPeopleFilterOptions={setPeopleFilterOptions}
        zoomLevel={zoomLevel}
        date={date}
        updateLeftBorderDate={updateLeftBorderDate}
        refreshCounter={refreshCounter}
        refreshFunc={refreshFunc}
      />
    </>
  )
}

export default SchedulePage
