import { LocationTime } from "./location-time"
import { TripType } from "./trip"

export const PLACEHOLDER_VEHICLE_ID = 11

export interface VehicleTrip {
  id: number
  routeNumber: string
  route: LocationTime[]
  type: TripType
  timetableSummary: TimetableSummary
}

interface TimetableSummary {
  originRegionName: string
  destinationRegionName: string
  scheduledDeparture: string
}

export interface GPSInfo {
  lastUpdated: string
  longitude: number
  latitude: number
  heading: number
  elevation?: number
  numberOfSatellites?: number
  speed?: number
  acceleration?: {
    x: number
    y: number
    z: number
  }
}

interface TelemetryDataPoint {
  asOf: string
  value: number | string | boolean
}

interface IpadTelematics {
  batteryPercentage: TelemetryDataPoint
  batteryState: TelemetryDataPoint
  isConnectedToNfcReader: TelemetryDataPoint
  isConnectedToQrCodeScanner: TelemetryDataPoint
  isConnectedToBusServer: TelemetryDataPoint
  isUsingWifi: TelemetryDataPoint
  thermalState: TelemetryDataPoint
}

interface VehicleTelematics {
  chargerStatus: TelemetryDataPoint
  batteryPercentage: TelemetryDataPoint
  batteryVoltage: TelemetryDataPoint
  batteryCurrent: TelemetryDataPoint
  insideTemperature: TelemetryDataPoint
  outsideTemperature: TelemetryDataPoint
  odometerReading: TelemetryDataPoint
  highestBatteryTemp: TelemetryDataPoint
  lowestBatteryTemp: TelemetryDataPoint
  lowVoltageCircuitVoltage: TelemetryDataPoint
}

export interface VehicleResources {
  seat: number
  wheelchair: number
  bicycle: number
}

export interface VehicleData {
  id: number
  plateNumber: string
  name: string
  hasToilet?: boolean
  hasWifi?: boolean
  colour?: string
  idealBatteryCapacity?: number
  currentBatteryCapacity?: number
  targetStateOfCharge?: number
  previousTrip?: VehicleTrip
  nextTrip?: VehicleTrip
  gps?: GPSInfo
  secondaryGps?: GPSInfo
  ipadTelematics?: IpadTelematics
  vehicleTelematics?: VehicleTelematics
  vehicleIdentificationNumber?: string
  isBackupVehicle?: boolean
  brand?: string
  type?: string
  resources?: VehicleResources
  ownerId?: number
}

export interface VehicleSummary {
  id: number
  name: string
  plateNumber: string
}

export interface VehicleModel {
  name: string
}
