// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { format, parseISO } from "date-fns"
import Timeline, {
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from "react-calendar-timeline"
import "react-calendar-timeline/lib/Timeline.css"
import styled from "styled-components"

import { Loading, Text, useMediaQuery } from "@kiwicom/orbit-components"
import { QueryMap } from "@kiwicom/orbit-components/lib/hooks/useMediaQuery/types"

import { searchActivities } from "api/activities"
import { EmberApiError } from "api/errors"

import { ActivityModal } from "components/admin-shifts/activity-modal"
import { EmberCheckboxOptionProps } from "components/generic/formik-inputs"

import { Activity } from "types/activity"
import { DriverRota, FlatShift } from "types/driver"
import { TripSummary } from "types/trip"
import { PLACEHOLDER_VEHICLE_ID } from "types/vehicle"

import {
  formatAsAbbreviatedDateWithoutYear,
  getDefaultTimezone,
} from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"
import { getPersonName } from "utils/name-utils"
import { useGlobalFetcher } from "utils/state-utils"

import {
  CalendarWrapper,
  CustomDateHeader,
  itemRenderer,
} from "./timeline-subcomponents"

export enum ScheduleType {
  vehicles = "vehicles",
  people = "people",
}

export enum ActiveFilterValue {
  all = "all",
  active = "active",
}

export enum VehicleTypeFilterValue {
  all = "all",
  primary = "primary",
}

const GroupWrapper = styled.div`
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 21px;
`

enum ZoomLevel {
  hourView = "hourView",
  dayView = "dayView",
  weekView = "weekView",
}

export const zoomLevelArray = [
  ZoomLevel.hourView,
  ZoomLevel.dayView,
  ZoomLevel.weekView,
]

const ZoomLevelMapping = {
  // The number represents the amount of hours in the entire canvas,
  // and the position in the array represents the size of view.
  // For example, the smallest screen with maximal zoom would render 4 hours.
  // Assuming the buffer is '3', the visible part of the canvas would be of 1.33 hours.

  // This should be different for people and for vehicles
  hourView: [4, 5, 8, 10, 13, 16],
  dayView: [20, 24, 28, 38, 48, 58],
  weekView: [60, 100, 160, 240, 300, 400],
}

const mapMediaToNumericViewSize = (media: QueryMap<boolean | null>): number => {
  if (media.isMediumMobile === false) {
    return 0
  } else if (media.isLargeMobile === false) {
    return 1
  } else if (media.isTablet === false) {
    return 2
  } else if (media.isDesktop === false) {
    return 3
  } else if (media.isLargeDesktop === false) {
    return 4
  } else {
    return 5
  }
}

const mapZoomLevelToCanvasSize = (
  level: ZoomLevel,
  media: QueryMap<boolean | null>
): number => {
  return (
    ZoomLevelMapping[level][mapMediaToNumericViewSize(media)] * 1000 * 60 * 60
  )
}

const vehicleSortFunc = (a, b) => {
  if (a.isPrimary !== b.isPrimary) {
    if (a.isPrimary) {
      return -1
    } else {
      return 1
    }
  }
  if (a.isSpecial !== b.isSpecial) {
    if (a.isSpecial) {
      return 1
    } else {
      return -1
    }
  }
  return a.id < b.id ? -1 : 1
}

const handleActivitiesRequest = (
  setActivities,
  setActiveVehicleIds,
  setItems,
  start,
  end,
  setLoadingDone
) => {
  searchActivities({
    searchParams: {
      start: new Date(start).toISOString(),
      end: new Date(end).toISOString(),
      vehicles: [],
      includeTrips: true,
    },
    onSuccess: ({
      activities,
      trips,
    }: {
      activities: Activity[]
      trips: TripSummary[]
    }) => {
      const parsedItems = []
      const parsedGroups = []
      const parsedActivities: any = {}
      const vehicleIds = []

      const tripParser = (item) => ({
        id: `trip_${item.id}`,
        group: item.vehicle ? item.vehicle.id : 0,
        title: `${item.routeNumber}`,
        destination: item.destination.location.regionName,
        start_time: parseISO(
          item.origin.departure.actual
            ? item.origin.departure.actual
            : item.origin.departure.scheduled
        ).getTime(),
        end_time: parseISO(
          item.destination.arrival.actual
            ? item.destination.arrival.actual
            : item.destination.arrival.scheduled
        ).getTime(),
        type: "trip",
      })

      const activityParser = (item) => ({
        id: `activity_${item.id}`,
        group: item.vehicle ? item.vehicle.id : 0,
        title: item.type,
        start_time: parseISO(item.start).getTime(),
        end_time: parseISO(item.end).getTime(),
        type: item.type,
      })

      const genericParser = (item, typedParser) => {
        if (item.vehicle && !vehicleIds.includes(item.vehicle.id)) {
          vehicleIds.push(item.vehicle.id)
          if (item.vehicle.id == PLACEHOLDER_VEHICLE_ID) {
            parsedGroups.push({
              id: item.vehicle.id,
              title: item.vehicle.name,
            })
          } else {
            parsedGroups.push({
              id: item.vehicle.id,
              title: item.vehicle.plateNumber
                ? item.vehicle.plateNumber
                : item.vehicle.name,
            })
          }
        }
        if (!item.vehicle && !vehicleIds.includes(0)) {
          vehicleIds.push(0)
          parsedGroups.push({ id: 0, title: "Unassigned" })
        }
        parsedItems.push(typedParser(item))
        parsedActivities[item.id] = item
      }

      activities.forEach((item) => {
        genericParser(item, activityParser)
      })

      trips.forEach((item) => {
        genericParser(item, tripParser)
      })
      setActivities(parsedActivities)
      setActiveVehicleIds(vehicleIds)
      setItems(parsedItems)
      setLoadingDone()
    },
    onError: (error: EmberApiError) => {
      // 2024-02-07 - adding this as there was no error handling until now, but we should presumably
      // display the error and call `setLoadingDone`
      console.error(error.message)
    },
  })
}

const mapUnassignedShiftsToDrivers = (
  unassignedShifts: FlatShift[]
): DriverRota[] => {
  const unassignedShiftsByDate = {}
  let unassignedDriversAmount = 0
  unassignedShifts.forEach((shift) => {
    const shift_date = formatAsAbbreviatedDateWithoutYear(
      shift.scheduledStart,
      getDefaultTimezone()
    )
    if (unassignedShiftsByDate[shift_date]) {
      unassignedShiftsByDate[shift_date].push(shift)
      if (unassignedShiftsByDate[shift_date].length > unassignedDriversAmount) {
        unassignedDriversAmount = unassignedShiftsByDate[shift_date].length
      }
    } else {
      unassignedShiftsByDate[shift_date] = [shift]
      if (unassignedDriversAmount == 0) {
        unassignedDriversAmount = 1
      }
    }
  })
  const unassignedDrivers = Array(unassignedDriversAmount)
    .fill(null)
    .map(
      (_, i) =>
        ({
          shifts: [],
          timeOff: [],
          name: `Unassigned ${i + 1}`,
          id: -1 * (i + 1),
        } as DriverRota)
    )
  Object.keys(unassignedShiftsByDate).forEach((date) => {
    unassignedShiftsByDate[date].forEach((shift, i) => {
      unassignedDrivers[i].shifts.push(shift)
    })
  })
  return unassignedDrivers
}

interface DescriptionWrapperProps {
  clickable: boolean
  highlighted: boolean
  type:
    | "off"
    | "booked-time-off"
    | "special"
    | "very-early-morning-shift"
    | "early-morning-shift"
    | "morning-shift"
    | "early-afternoon-shift"
    | "afternoon-shift"
    | "evening-shift"
    | "late-shift"
}

const handleRotaRequest = (
  setUnassignedPeople,
  setActivePeopleIds,
  setItems,
  start,
  end,
  setLoadingDone
) => {
  fetchFromAPIBase({
    path: `/v1/staff/rota/?start=${format(
      new Date(start),
      "yyyy-MM-dd"
    )}&end=${format(new Date(end), "yyyy-MM-dd")}&unassigned_shifts=${true}`,
  }).subscribe((response) => {
    if (response && !response.error) {
      const parsedItems = []
      let drivers = [] as DriverRota[]
      if (response.drivers) {
        drivers = response.drivers
      } else {
        drivers = response
      }
      const unassignedPeople = mapUnassignedShiftsToDrivers(
        response.unassignedShifts
      )
      const activePeopleIds = unassignedPeople
        ? unassignedPeople.map((p) => p.id.toString())
        : []
      if (unassignedPeople) {
        drivers = drivers.concat(unassignedPeople)
      }

      setUnassignedPeople(
        unassignedPeople.map((p) => ({
          id: p.id.toString(),
          title: getPersonName(p),
        }))
      )

      drivers.forEach((driver) => {
        driver.shifts.forEach((shift) => {
          let type: DescriptionWrapperProps["type"]
          start = parseISO(shift.scheduledStart)

          if (start.getHours() < 2) {
            type = "late-shift"
          } else if (start.getHours() < 6) {
            type = "very-early-morning-shift"
          } else if (start.getHours() < 9) {
            type = "early-morning-shift"
          } else if (start.getHours() < 12) {
            type = "morning-shift"
          } else if (start.getHours() < 15) {
            type = "early-afternoon-shift"
          } else if (start.getHours() < 17) {
            type = "afternoon-shift"
          } else if (start.getHours() < 19) {
            type = "evening-shift"
          } else {
            type = "late-shift"
          }
          parsedItems.push({
            id: `shift_${shift.id}`,
            group: driver.uid ? driver.uid : driver.id.toString(),
            title: "Shift",
            start_time: parseISO(shift.scheduledStart).getTime(),
            end_time: parseISO(shift.scheduledEnd).getTime(),
            type: type,
          })
        })
        driver.timeOff.forEach((timeOff) => {
          parsedItems.push({
            id: `timeOff_${timeOff.id}`,
            group: driver.uid ? driver.uid : driver.id.toString(),
            title:
              timeOff.type == "annual_leave" ? "Annual Leave" : "Other Leave",
            start_time: parseISO(timeOff.start).getTime(),
            end_time: parseISO(timeOff.end).getTime(),
            type: `timeOff_${
              timeOff.type == "annual_leave" ? "annual_leave" : "other"
            }`,
          })
        })
        if (
          driver.uid &&
          (driver.timeOff.length > 0 || driver.shifts.length > 0)
        ) {
          activePeopleIds.push(driver.uid)
        }
      })
      setItems(parsedItems)
      setActivePeopleIds(activePeopleIds)
      setLoadingDone()
    }
  })
}

interface ScheduleFilter {
  vehicles: {
    ids: string[]
    type: VehicleTypeFilterValue
  }
  people: {
    ids: string[]
    type: ActiveFilterValue
  }
}

interface EmberTimelineProps {
  scheduleType: ScheduleType
  filterSchedule: ScheduleFilter
  zoomLevel: number
  date: Date
  refreshCounter: number
  setVehicleFilterOptions: (options: EmberCheckboxOptionProps[]) => void
  setPeopleFilterOptions: (options: EmberCheckboxOptionProps[]) => void
  refreshFunc: () => void
  updateLeftBorderDate: (value: number) => void
}

const EmberTimeline = ({
  scheduleType,
  filterSchedule,
  zoomLevel,
  date,
  refreshCounter,
  setVehicleFilterOptions,
  setPeopleFilterOptions,
  refreshFunc,
  updateLeftBorderDate,
}: EmberTimelineProps) => {
  const [activities, setActivities] = useState()
  const [showActivityModal, setShowActivityModal] = useState(false)
  const [activityToEdit, setActivityToEdit] = useState<Activity>(null)
  const [timelineKey, setTimelineKey] = useState(0)
  const [allVehicles, setAllVehicles] = useState([])
  const [visibleVehicles, setVisibleVehicles] = useState([])
  const [activeVehicleIds, setActiveVehicleIds] = useState([])
  const [allPeople, setAllPeople] = useState([])
  const [unassignedPeople, setUnassignedPeople] = useState([])
  const [activePeopleIds, setActivePeopleIds] = useState([])
  const [visiblePeople, setVisiblePeople] = useState([])
  const visibleGroups = { vehicles: visibleVehicles, people: visiblePeople }[
    scheduleType
  ]
  const setVisibleGroups = {
    vehicles: setVisibleVehicles,
    people: setVisiblePeople,
  }[scheduleType]
  const [items, setItems] = useState([])
  const [scrollUpdateTimeoutId, setScrollUpdateTimeoutId] = useState(null)
  const media = useMediaQuery()
  const [canvasInterval, setCanvasInterval] = useState([0, 0])
  const [viewInterval, setViewInterval] = useState([0, 0])
  const [loadingData, setLoadingData] = useState(false)
  const setLoadingDone = () => setLoadingData(false)
  const currentFilter = filterSchedule[scheduleType]

  const { data: drivers } = useGlobalFetcher("drivers")
  const { data: vehicles } = useGlobalFetcher("vehicles")

  useEffect(() => {
    if (scrollUpdateTimeoutId) {
      clearTimeout(scrollUpdateTimeoutId)
    }
    setScrollUpdateTimeoutId(
      setTimeout(() => {
        refreshFunc()
      }, 1000)
    )
  }, [JSON.stringify(canvasInterval)])

  useEffect(() => {
    if (!loadingData && visibleGroups.length === 0) {
      setVisibleGroups([{ id: "emptyState", title: "(none)" }])
    }
  }, [visibleGroups.length, loadingData])

  useEffect(() => {
    if (scheduleType == ScheduleType.vehicles) {
      const vehicleGroups = []
      vehicles.forEach((vehicle) => {
        vehicleGroups.push({
          id: vehicle.id,
          title: vehicle.plateNumber ? vehicle.plateNumber : vehicle.name,
          isPrimary:
            vehicle.isBackupVehicle === false || vehicle.brand === "Ember",
          isSpecial: [
            "Cancelled Service",
            "Placeholder Yutong Vehicle",
          ].includes(vehicle.name),
        })
      })
      vehicleGroups.push({
        id: 0,
        title: "Unassigned",
        isPrimary: true,
        isSpecial: true,
      })
      vehicleGroups.sort(vehicleSortFunc)
      setAllVehicles(vehicleGroups)
    }
  }, [vehicles.length, scheduleType])

  useEffect(() => {
    if (scheduleType === ScheduleType.vehicles) {
      let nextVisibleVehicles = []

      if (currentFilter.ids.length > 0) {
        nextVisibleVehicles = allVehicles.filter((v) =>
          currentFilter.ids.includes(v.id.toString())
        )
      } else {
        if (currentFilter.type !== "all") {
          nextVisibleVehicles = allVehicles.filter(
            (v) => v.isPrimary || activeVehicleIds.includes(v.id)
          )
        } else {
          nextVisibleVehicles = allVehicles
        }
      }
      if (
        JSON.stringify(visibleVehicles) !== JSON.stringify(nextVisibleVehicles)
      ) {
        setVisibleVehicles(nextVisibleVehicles)
      }
    }
  }, [
    allVehicles.length,
    scheduleType,
    JSON.stringify(currentFilter),
    JSON.stringify(activeVehicleIds),
  ])

  useEffect(() => {
    if (scheduleType === ScheduleType.people) {
      let nextVisiblePeople = []

      if (currentFilter.ids.length > 0) {
        nextVisiblePeople = allPeople.filter((p) =>
          currentFilter.ids.includes(p.id)
        )
      } else {
        if (currentFilter.type !== "all") {
          nextVisiblePeople = allPeople.filter((p) =>
            activePeopleIds.includes(p.id)
          )
        } else {
          nextVisiblePeople = allPeople
        }
      }
      if (JSON.stringify(visiblePeople) !== JSON.stringify(nextVisiblePeople)) {
        setVisiblePeople(nextVisiblePeople)
      }
    }
  }, [
    allPeople.length,
    scheduleType,
    JSON.stringify(currentFilter),
    JSON.stringify(activePeopleIds),
  ])

  useEffect(() => {
    if (scheduleType == ScheduleType.people) {
      const peopleGroups = []
      drivers.forEach((person) => {
        peopleGroups.push({
          id: person.uid,
          title: getPersonName(person),
        })
      })
      peopleGroups.sort((a, b) =>
        a.title < b.title ? 1 : a.title > b.title ? -1 : 0
      )
      peopleGroups.reverse()
      setAllPeople(peopleGroups.concat(unassignedPeople))
    }
  }, [drivers.length, unassignedPeople.length, scheduleType])

  useEffect(() => {
    setTimelineKey(timelineKey + 1)
  }, [zoomLevel])

  useEffect(() => {
    setItems([])
    setActivities(null)
  }, [scheduleType])

  useEffect(() => {
    if (scheduleType === ScheduleType.vehicles) {
      const mapVehicleToOption = (v) => ({
        label: v.title,
        value: v.id.toString(),
      })
      if (currentFilter.type === "all") {
        setVehicleFilterOptions(allVehicles.map(mapVehicleToOption))
      } else {
        setVehicleFilterOptions(
          allVehicles
            .filter((v) => v.isPrimary || activeVehicleIds.includes(v.id))
            .map(mapVehicleToOption)
        )
      }
    }
  }, [
    scheduleType,
    allVehicles.length,
    currentFilter.type,
    JSON.stringify(activeVehicleIds),
  ])

  useEffect(() => {
    if (scheduleType === ScheduleType.people) {
      const mapPersonToOption = (p) => ({
        label: p.title,
        value: p.id,
      })

      if (currentFilter.type === "all") {
        setPeopleFilterOptions(allPeople.map(mapPersonToOption))
      } else {
        setPeopleFilterOptions(
          allPeople
            .filter((p) => activePeopleIds.includes(p.id))
            .map(mapPersonToOption)
        )
      }
    }
  }, [
    scheduleType,
    allPeople.length,
    currentFilter.type,
    JSON.stringify(activePeopleIds),
  ])

  // bufferFactor is the ratio between the visible calendar and the entire calendar canvas.
  // The default value is 3, and a lot of the calculation is based on it, so don't change it
  // unless you know what you're doing.
  const bufferFactor = 3
  // buffer is the size of the visible calendar in milliseconds
  const buffer =
    media.isDesktop !== null
      ? mapZoomLevelToCanvasSize(zoomLevelArray[zoomLevel], media) /
        bufferFactor
      : null

  useEffect(() => {
    setCanvasInterval([viewInterval[0] - buffer, viewInterval[0] + 2 * buffer])
    setViewInterval([viewInterval[0], viewInterval[0] + buffer])
  }, [buffer])

  useEffect(() => {
    setCanvasInterval([date.getTime() - buffer, date.getTime() + 2 * buffer])
    setViewInterval([date.getTime(), date.getTime() + buffer])
  }, [JSON.stringify(date)])

  useEffect(() => {
    setLoadingData(true)
    if (scheduleType == ScheduleType.vehicles) {
      handleActivitiesRequest(
        setActivities,
        setActiveVehicleIds,
        setItems,
        canvasInterval[0],
        canvasInterval[1],
        setLoadingDone
      )
    } else {
      handleRotaRequest(
        setUnassignedPeople,
        setActivePeopleIds,
        setItems,
        canvasInterval[0],
        canvasInterval[1],
        setLoadingDone
      )
    }
  }, [
    scheduleType,
    refreshCounter,
    JSON.stringify(filterSchedule.vehicles.ids),
  ])

  const editActivity = (itemId, _e, _time) => {
    const [type, IdStr] = itemId.split("_")
    if (type == "activity") {
      setActivityToEdit(activities && activities[parseInt(IdStr)])
      setShowActivityModal(true)
    }
  }

  return (
    <>
      {showActivityModal && (
        <ActivityModal
          dateRange={null}
          activityToEdit={activityToEdit}
          refreshActivities={refreshFunc}
          handleClose={() => {
            setShowActivityModal(false)
            setActivityToEdit(null)
          }}
          initialVehicleId={
            currentFilter.ids.length > 0 ? currentFilter.ids[0] : null
          }
        />
      )}
      <CalendarWrapper isDesktop={media.isDesktop}>
        {canvasInterval[0] && visibleGroups.length > 0 && (
          <Timeline
            key={timelineKey}
            groups={visibleGroups}
            items={items}
            stackItems={true}
            onItemSelect={editActivity}
            sidebarWidth={media.isTablet === true ? 105 : 65}
            canMove={false}
            canResize={false}
            buffer={3.5}
            minZoom={buffer}
            maxZoom={buffer}
            defaultTimeStart={viewInterval[0]}
            defaultTimeEnd={viewInterval[1]}
            onBoundsChange={(canvasTimeStart, canvasTimeEnd) => {
              setCanvasInterval([canvasTimeStart, canvasTimeEnd])
              setViewInterval([
                canvasTimeStart + buffer,
                canvasTimeEnd - buffer,
              ])
              updateLeftBorderDate(
                canvasTimeStart + (canvasTimeEnd - canvasTimeStart) / 2
              )
            }}
            itemHeightRatio={0.9}
            lineHeight={60}
            groupRenderer={({ group }) => {
              return (
                <GroupWrapper>
                  <Text
                    align="center"
                    weight="medium"
                    type="white"
                    size="small"
                  >
                    {group.title}
                  </Text>
                </GroupWrapper>
              )
            }}
            itemRenderer={itemRenderer}
          >
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div {...getRootProps()}>
                      <LoadingWrapper>
                        {loadingData && <Loading type="inlineLoader" />}
                      </LoadingWrapper>
                    </div>
                  )
                }}
              </SidebarHeader>
              <CustomDateHeader
                headerUnit={zoomLevel == 2 ? "month" : "day"}
                className="rct-dateHeader rct-dateHeader-primary"
                primary
              />
              <CustomDateHeader headerUnit={null} className="rct-dateHeader" />
            </TimelineHeaders>
            <TodayMarker />
          </Timeline>
        )}
        {loadingData && visibleGroups.length == 0 && (
          <Loading type="pageLoader" />
        )}
      </CalendarWrapper>
    </>
  )
}

export default EmberTimeline
