import React from "react"

import { AdminLayout } from "components/layout-custom"
import SchedulePage from "components/schedule"

const Page = () => (
  <AdminLayout title="Schedule" padded={false}>
    <SchedulePage />
  </AdminLayout>
)

export default Page
